import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import {
    LinearProgress,
    Button,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
    makeStyles,
} from '@material-ui/core';
import { Header, Tabs, TableSeparator } from 'RaisisComponents';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ClientDetailsForm from 'components/shared/ClientDetailsForm';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PropTypes from 'prop-types';
import { clientRoutes } from 'routes';
import API from 'utils/axios';
import ClientConfigurator from 'components/shared/ClientConfigurator';
import ClientMembershipDetails from './ClientMembershipDetails';
import ClientTableFooter from './ClientTableFooter';
import { errorHandling } from 'utils';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const UpdateClientPage = () => {
    const styles = useStyles();
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = params;
    const [client, setClient] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [configActiveTab, setConfigActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    const getClient = async () => {
        try {
            const res = await API.get(`customer/${id}`);
            setClient(res.data.data);
        } catch {
            history.push(clientRoutes.read);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClient = async () => {
        try {
            setIsUpdating(true);

            await API.delete('/customer_delete', {
                params: {
                    id: client.id,
                },
            });

            enqueueSnackbar('Ștergerea clientului a fost realizată cu success', { variant: 'success' });

            history.push(clientRoutes.read);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    const handleBlockClient = async () => {
        try {
            setIsUpdating(true);

            await API.delete('/customer_block', {
                params: {
                    id: client.id,
                    isBlocked: !client.isBlocked,
                },
            });

            await getClient();

            enqueueSnackbar(
                client.isBlocked
                    ? 'Deblocarea clientului a fost realizată cu success'
                    : 'Blocarea clientului a fost realizată cu success',
                { variant: 'success' },
            );
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    useEffect(() => {
        getClient();
        // eslint-disable-next-line
    }, [id]);

    return (
        <>
            <Helmet>
                <title>{`Editare Client${client ? ` - ${client.name}` : ''}`}</title>
            </Helmet>

            <div className="relative">
                {loading ? (
                    <div className="mx-auto my-16">
                        <LinearProgress />
                    </div>
                ) : (
                    <>
                        <Header
                            pageTitle={
                                <div className="flex items-center">
                                    <FingerprintIcon className="mr-4" style={{ fontSize: '3rem' }} />
                                    {client.name}
                                </div>
                            }
                            action={
                                <div className="flex gap-4">
                                    <a
                                        href={`https://${client.subdomain}.${process.env.REACT_APP_DOMAIN_URL}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button
                                            startIcon={<VisibilityOutlinedIcon />}
                                            color="primary"
                                            disabled={isUpdating}
                                        >
                                            Accesează contul
                                        </Button>
                                    </a>

                                    {client.completedStatus === 'COMPLETED' && (
                                        <Button
                                            color="secondary"
                                            startIcon={client.isBlocked ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                                            disabled={isUpdating}
                                            onClick={handleBlockClient}
                                        >
                                            {client.isBlocked ? 'Deblochează utilizator' : 'Blochează utilizator'}
                                        </Button>
                                    )}

                                    <Button
                                        className={styles.error}
                                        startIcon={<DeleteIcon />}
                                        disabled={isUpdating}
                                        onClick={handleDeleteClient}
                                    >
                                        Șterge utilizator
                                    </Button>
                                </div>
                            }
                        />

                        <div className="page-container">
                            <div className="mb-4">
                                <Tabs
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    // tabs={[
                                    //     'Activitate',
                                    //     'Cashflow',
                                    //     'Detalii abonament',
                                    //     'Detalii client',
                                    //     'Configurator',
                                    // ]}
                                    tabs={['Detalii abonament', 'Detalii client', 'Configurator']}
                                />
                            </div>

                            {/* {activeTab === 0 && <ClientActivity clientId={client.id} />}

                        {activeTab === 1 && <ClientCashflow clientId={client.id} />} */}

                            {activeTab === 0 && <ClientMembershipDetails client={client} />}

                            {activeTab === 1 && (
                                <ClientDetailsForm
                                    canInteractWithAllFields={true}
                                    client={client}
                                    submitButton={
                                        <div className="mt-12">
                                            <Button
                                                type="submit"
                                                startIcon={<CheckIcon />}
                                                color="primary"
                                                disabled={isUpdating}
                                            >
                                                Actualizează datele
                                            </Button>
                                        </div>
                                    }
                                    onSuccess={getClient}
                                    editable={!isUpdating}
                                />
                            )}

                            {activeTab === 2 && (
                                <div className="mt-8 inline-flex flex-col gap-8">
                                    <div className="inline-flex">
                                        <Tabs
                                            small
                                            activeTab={configActiveTab}
                                            setActiveTab={setConfigActiveTab}
                                            tabs={(() => {
                                                // const arr = [
                                                //     'CRM',
                                                //     'Contabilitate',
                                                //     'Project Management',
                                                //     'Antrepriza',
                                                //     'Booking',
                                                //     'Intermedieri imobiliare',
                                                //     'Facility Management',
                                                // ].filter((_, index) => moduleChoice[index]);

                                                // arr.push('Configurator teme');

                                                // return arr;

                                                return ['Configurator teme'];
                                            })()}
                                        />
                                    </div>

                                    <ClientConfigurator
                                        route={'/customer/theme'}
                                        configTabs={(() => {
                                            // const arr = [
                                            //     'CRM',
                                            //     'Contabilitate',
                                            //     'Project Management',
                                            //     'Antrepriza',
                                            //     'Booking',
                                            //     'Intermedieri imobiliare',
                                            //     'Facility Management',
                                            // ].filter((_, index) => moduleChoice[index]);

                                            // arr.push('Configurator teme');
                                            // return arr;

                                            return ['Configurator teme'];
                                        })()}
                                        configActiveTab={configActiveTab}
                                        client={client}
                                        submitButton={
                                            <div className="mt-12">
                                                <Button
                                                    type="submit"
                                                    startIcon={<CheckIcon />}
                                                    color="primary"
                                                    disabled={isUpdating}
                                                >
                                                    Actualizează datele
                                                </Button>
                                            </div>
                                        }
                                        onSuccess={getClient}
                                        editable={!isUpdating}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const ClientActivity = (props) => {
    const { clientId } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {clientId ? (
                <>
                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <TableContainer component={Box}>
                            <Table>
                                <TableBody>
                                    <TableSeparator />
                                    {[1, 2, 3, 4, 5].map((index) => (
                                        // eslint-disable-next-line
                                        <TableRow key={index}>
                                            <TableCell>12 APR 2021, 8:30</TableCell>
                                            <TableCell>
                                                A fost emisa <Link href="/">Factura #7</Link>, in valoare de{' '}
                                                <span className="font-bold">560 lei</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            ) : null}
        </>
    );
};
ClientActivity.propTypes = {
    clientId: PropTypes.string,
};
ClientActivity.defaultProps = {
    clientId: null,
};

const ClientCashflow = (props) => {
    const { clientId } = props;

    return (
        <>
            <TableContainer component={Box}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ultima factură</TableCell>
                            <TableCell>Dată achitare</TableCell>
                            <TableCell>Suma</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableSeparator />
                        {[1, 2, 3, 4, 5].map((index) => (
                            // eslint-disable-next-line
                            <TableRow key={index}>
                                <TableCell>18 AUG 2021</TableCell>
                                <TableCell>19 AUG 2021</TableCell>
                                <TableCell>
                                    560 lei - <Link href="/">Factura #7</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ClientTableFooter />
        </>
    );
};
ClientCashflow.propTypes = {
    clientId: PropTypes.string,
};
ClientCashflow.defaultProps = {
    clientId: null,
};

export default UpdateClientPage;
