import { Layout } from 'RaisisComponents';
import React, { useState, useEffect } from 'react';
import Root from 'routes/root';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { clientRoutes, mainRoutes, userRoutes } from 'routes';
import { SnackbarProvider } from 'notistack';
import UserContext from 'contexts/UserContext';
import SubscriptionTypeContext from 'contexts/SubscriptionTypeContext';
import FeatureContext from 'contexts/FeatureContext';
import PermissionContext from 'contexts/PermissionContext';
import API from 'utils/axios';
import { whoAmI, logout } from 'utils';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';

import PeopleIcon from '@material-ui/icons/People';
// import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
// import AssignmentIcon from '@material-ui/icons/Assignment';
// import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles({
    root: {
        margin: '5px 0',
    },
});

const App = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    // --- CONTEXTS
    const [subscriptionTypes, setSubscriptionTypes] = useState(null);
    const [features, setFeatures] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [user, setUser] = useState(-1);
    const [globalLoading, setGlobalLoading] = useState(true);

    /*
        Context Values from API
    */
    const getSubTypes = async () => {
        try {
            const res = await API.get('subscription_types');
            setSubscriptionTypes(res.data.data || []);
        } catch (err) {
            console.error(err);
            setSubscriptionTypes([]);
        }
    };

    const getFeatures = async () => {
        try {
            const res = await API.get('features');
            setFeatures(res.data.data || []);
        } catch (err) {
            console.error('Could not fetch FEATURES!');
            console.error(err);
            setFeatures([]);
        }
    };

    const getPermissions = async () => {
        try {
            const res = await API.get('permissions');
            setPermissions(res.data.data || []);
        } catch (err) {
            console.error('Could not fetch PERMISSIONS!');
            console.error(err);
            setPermissions([]);
        }
    };

    /*
        User manipulation on: page load / login / logout
    */
    useEffect(() => {
        const nullifyData = () => {
            setGlobalLoading(true);
            setSubscriptionTypes(null);
            setFeatures(null);
            setPermissions(null);
        };

        const clearData = () => {
            setSubscriptionTypes([]);
            setFeatures([]);
            setPermissions([]);
        };

        const getUser = async () => {
            const token = localStorage.getItem('accessToken');

            if (!token) {
                console.log(token, 1);

                setUser(null);
                clearData();
                setTimeout(() => {
                    history.push(mainRoutes.login);
                });
            } else {
                API.defaults.headers.common.Authorization = token;
                const findUser = await whoAmI();

                if (findUser === null) {
                    console.log(findUser, 2);

                    setUser(null);
                    clearData();
                    setTimeout(() => {
                        history.push(mainRoutes.login);
                    });
                } else {
                    console.log(findUser, 3);

                    setUser(findUser);
                    nullifyData();
                    setTimeout(() => {
                        getSubTypes();
                        getFeatures();
                        getPermissions();
                    });
                }
            }
        };

        /*
            Initial user check
        */

        if (user === -1) {
            console.log(user, 4);
            getUser();
        }

        /*
            This case applies when a user logs out manually
            Erase all data, and push to login page
        */
        if (user === null) {
            console.log(user, 5);

            clearData();
            setTimeout(() => {
                history.push(mainRoutes.login);
            });
        }

        /*
            User logs in successfully
            Fetching all the needed data for contexts
        */
        if (user !== null && user !== -1) {
            console.log(user, 6);

            nullifyData();
            setTimeout(() => {
                getSubTypes();
                getFeatures();
                getPermissions();
            });
        }
    }, [user, history]);

    /*
        Loading
    */
    useEffect(() => {
        if (globalLoading && subscriptionTypes !== null && features !== null && permissions !== null && user !== -1) {
            setGlobalLoading(false);
        }
    }, [globalLoading, subscriptionTypes, features, permissions, user]);

    return (
        <>
            <Helmet>
                <title>M2M Superadmin</title>
            </Helmet>

            <SnackbarProvider
                iconVariant={{
                    success: (
                        <div className="flex items-center">
                            <CheckIcon />
                            <div className="w-2" />
                        </div>
                    ),
                    error: (
                        <div className="flex items-center">
                            <ErrorOutlineIcon />
                            <div className="w-2" />
                        </div>
                    ),
                }}
                classes={{
                    variantSuccess: classes.root,
                    variantError: classes.root,
                    variantWarning: classes.root,
                    variantInfo: classes.root,
                }}
            >
                {globalLoading ? (
                    <div className="flex h-screen w-full items-center justify-center bg-black-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <UserContext.Provider
                        value={{
                            user,
                            setUser,
                        }}
                    >
                        <SubscriptionTypeContext.Provider value={subscriptionTypes}>
                            <FeatureContext.Provider value={features}>
                                <PermissionContext.Provider value={permissions}>
                                    <Layout
                                        sidebar={location.pathname !== '/login'}
                                        platform="Superadmin"
                                        logoAction={() => history.push(clientRoutes.read)}
                                        userName={user?.profile?.name ?? ''}
                                        userDropdownActions={[
                                            {
                                                name: 'Editează profil',
                                                handle: () => history.push(userRoutes.updateRoot + user?.id),
                                            },
                                        ]}
                                        imageUrl="https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80"
                                        navItems={[
                                            {
                                                name: 'Gestionare clienți',
                                                onRoute: () => history.push(clientRoutes.read),
                                                icon: <PeopleIcon className="li-icon-width" />,
                                            },
                                            // {
                                            //     name: 'Cashflow ERP',
                                            //     onRoute: () => alert('Feature in development!'),
                                            //     icon: <EuroSymbolIcon className="li-icon-width" />,
                                            // },
                                            // {
                                            //     name: 'Template-uri abonamente',
                                            //     onRoute: () => alert('Feature in development!'),
                                            //     icon: <AssignmentIcon className="li-icon-width" />,
                                            // },
                                            // {
                                            //     name: (
                                            //         <>
                                            //             Template-uri automatizate
                                            //             <br />
                                            //             (politici &amp; mailuri &amp; contracte)
                                            //         </>
                                            //     ),
                                            //     onRoute: () => alert('Feature in development!'),
                                            //     icon: <SettingsIcon className="li-icon-width" />,
                                            // },
                                            // {
                                            //     name: 'Utilizatori Superadmin',
                                            //     onRoute: () => history.push(userRoutes.read),
                                            //     icon: <SupervisedUserCircleIcon className="li-icon-width" />,
                                            // },
                                            {
                                                name: 'Logout',
                                                onRoute: () =>
                                                    logout(setUser, () => {
                                                        history.push(mainRoutes.login);
                                                        setUser(null);
                                                        setSubscriptionTypes([]);
                                                        setFeatures([]);
                                                        setPermissions([]);
                                                    }),
                                                icon: <ExitToAppIcon className="li-icon-width" />,
                                            },
                                        ]}
                                    >
                                        <Root />
                                    </Layout>
                                </PermissionContext.Provider>
                            </FeatureContext.Provider>
                        </SubscriptionTypeContext.Provider>
                    </UserContext.Provider>
                )}
            </SnackbarProvider>
        </>
    );
};

export default App;
