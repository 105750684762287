import React, { useState, useEffect, useContext } from 'react';
import { ModuleWrapper, Header, CheckItem, Stepper, LabelWrapper, Dropdown, Tabs, DatePicker } from 'RaisisComponents';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import ClientDetailsForm from 'components/shared/ClientDetailsForm';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SubscriptionTypeContext from 'contexts/SubscriptionTypeContext';
import FeatureContext from 'contexts/FeatureContext';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import { clientRoutes } from 'routes';
import API from 'utils/axios';
import { errorHandling } from 'utils';
import * as yup from 'yup';

import ClientConfigurator from 'components/shared/ClientConfigurator';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AddClientPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    let query = useQuery();
    const id = query.get('id');
    const tab = query.get('tab');

    const [step, setStep] = useState(tab | 0);
    const [configActiveTab, setConfigActiveTab] = useState(0);

    const [addedClient, setAddedClient] = useState(null);
    const [loadingClient, setLoadingClient] = useState(true);

    useEffect(async () => {
        if (id) {
            try {
                const res = await API.get(`/customer/${id}`);
                const client = res.data.data;

                setAddedClient(client);

                console.log('*** customer ***', client);

                setLoadingClient(false);
            } catch (err) {
                console.error(err);
            }
        } else setLoadingClient(false);
    }, [id, tab]);

    // --- Client Details

    // --- ERP Modules
    const features = useContext(FeatureContext);
    const [moduleChoice, setModuleChoice] = useState(null); // key value pairs: id -- state
    const [modulePrice, setModulePrice] = useState(null); // key value pairs: id -- price
    const [moduleNameMapper, setModuleNameMapper] = useState(null); // key value pairs: name -- id

    useEffect(() => {
        if (features.length) {
            const mc = {};
            const mp = {};
            const mn = {};
            features.forEach((fe) => {
                fe.features.forEach((f) => {
                    mc[f.id] = false;
                    mp[f.id] = f.costPerMonth;
                    mn[f.name] = f.id;
                });
            });
            setModuleChoice(mc);
            setModulePrice(mp);
            setModuleNameMapper(mn);
        }
    }, [features]);

    const handleModuleChoiceChange = (id, val) => {
        setModuleChoice({
            ...moduleChoice,
            [id]: val,
        });
    };

    const getPriceOptions = () => {
        let monthly = 0;
        let yearly = 0;
        let lifetime = 0;

        Object.keys(modulePrice).forEach((key) => {
            if (moduleChoice[key]) monthly += modulePrice[key] * 1 * 1;
            if (moduleChoice[key]) yearly += modulePrice[key] * 0.9 * 12;
            if (moduleChoice[key]) lifetime += modulePrice[key] * 0.8 * 24;
        });

        monthly = monthly.toFixed(2);
        yearly = yearly.toFixed(2);
        lifetime = lifetime.toFixed(2);

        return {
            monthly,
            yearly,
            lifetime,
        };
    };

    const submitModuleERP = async () => {
        const clientModules = [];
        Object.keys(moduleChoice).forEach((key) => {
            if (moduleChoice[key]) clientModules.push(key);
        });

        if (!clientModules.length) {
            enqueueSnackbar('Alegeți cel puțin un modul ERP!', { variant: 'error' });
            return;
        }

        const reqBody = {
            id: addedClient.id,
            data: {
                subscriptionId: addedClient.subscriptionId,
                features: clientModules,
            },
        };

        try {
            const res = await API.post('customer_completion_process', reqBody);

            console.log('### customer_completion_process ###', res);

            setStep(2);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        }
    };

    // --- FINAL OFFER STATES
    const subscriptionTypes = useContext(SubscriptionTypeContext);

    const [finalOfferData, setFinalOfferData] = useState({
        finalPrice: '',
        hasDemo: false,
        demoDays: '',
    });

    const handleChangeFinalOfferData = (e) =>
        setFinalOfferData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const finalOfferSchema = yup.object().shape({
        finalPrice: yup.number().typeError('Prețul final este obligatoriu!').required('Prețul final este obligatoriu!'),
        hasDemo: yup.boolean().typeError().required(),
        demoDays: yup.mixed().when('hasDemo', {
            is: true,
            then: yup
                .number()
                .typeError('Numărul de zile este obligatoriu!')
                .min(1, 'Numărul de zile trebuie să fie mai mare ca 0!')
                .required('Numărul de zile este obligatoriu!'),
            otherwise: yup.string(),
        }),
    });

    const submitFinalOffer = async (e) => {
        e.preventDefault();

        try {
            await finalOfferSchema.validate(finalOfferData);

            const reqBody = {
                id: addedClient.id,
                data: {
                    subscriptionValue: finalOfferData.finalPrice,
                    hasDemo: finalOfferData.hasDemo,
                    numberOfDaysForDemo: finalOfferData.hasDemo ? finalOfferData.demoDays : 0,
                },
            };

            await API.post('customer_completion_process', reqBody);
            enqueueSnackbar('Clientul a fost adaugat cu succes!', { variant: 'success' });
            history.push(clientRoutes.read);
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (ev) => {
            const message = 'Ești sigur că vrei să închizi?';
            ev.preventDefault();
            ev.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Update completion status
    const [updatingStatus, setUpdatingStatus] = useState(false);

    const updateCompletionStatus = async (completedStatus, prevStep) => {
        try {
            setUpdatingStatus(true);

            await API.patch('customer_status_update', undefined, {
                params: {
                    id: addedClient.id,
                    completedStatus,
                },
            });

            setStep(prevStep);
        } catch (error) {
            console.error(error);
        } finally {
            setUpdatingStatus(false);
        }
    };

    console.log(addedClient);

    return (
        <div className="relative">
            <Header
                pageTitle="Adaugă client nou"
                toolbar={
                    <Stepper
                        step={step}
                        steps={['Detalii client', 'Module ERP', 'Configurator ERP', 'Ofertă finală']}
                    />
                }
                toolbarSecondary={
                    step === 1 && addedClient ? (
                        <div className="align-start flex flex-col justify-start">
                            <div className="mb-2 flex w-max items-center">
                                <p className="mr-16 font-bold">Estimare pret:</p>

                                <p className="mr-16">
                                    Abonament lunar:
                                    <span className="font-bold">{` ${getPriceOptions().monthly} EUR`}</span>
                                </p>

                                <p className="mr-16">
                                    Abonament anual:
                                    <span className="font-bold">{` ${getPriceOptions().yearly} EUR`}</span>
                                </p>

                                <p>
                                    Licenta pe viata:
                                    <span className="font-bold">{` ${getPriceOptions().lifetime} EUR`}</span>
                                </p>
                            </div>
                            <p className="text-xs opacity-50">
                                Tot ce este toggle este taxat suplimentar. Prețurile de mai sus se vor actualiza în timp
                                real, în funcție de ce toggle-uri sunt activate.
                            </p>
                        </div>
                    ) : (
                        step === 2 &&
                        addedClient && (
                            //FIXME: Uncomment this line
                            // getPriceOptions().monthly > 0 && (
                            <Tabs
                                activeTab={configActiveTab}
                                setActiveTab={setConfigActiveTab}
                                tabs={(() => {
                                    // const arr = [
                                    //     'CRM',
                                    //     'Contabilitate',
                                    //     'Project Management',
                                    //     'Antrepriza',
                                    //     'Booking',
                                    //     'Intermedieri imobiliare',
                                    //     'Facility Management',
                                    // ].filter((_, index) => moduleChoice[index]);

                                    // arr.push('Configurator teme');

                                    // return arr;
                                    return ['Configurator teme'];
                                })()}
                            />
                        )
                        // )
                    )
                }
            />

            <div className="page-container">
                {/* --- CLIENT DETAILS FORM */}
                {step === 0 && !loadingClient && (
                    <DetaliiClient setStep={setStep} addedClient={addedClient} setAddedClient={setAddedClient} />
                )}

                {/* --- ERP MODULES CHOICE */}
                {/* {step === 1 && addedClient && moduleChoice && modulePrice && ( */}
                {step === 1 && moduleChoice && moduleNameMapper && (
                    <ModuleERP
                        setStep={setStep}
                        submitModuleERP={submitModuleERP}
                        moduleChoice={moduleChoice}
                        moduleNameMapper={moduleNameMapper}
                        handleModuleChoiceChange={handleModuleChoiceChange}
                    />
                )}

                {step === 2 && addedClient && (
                    <ConfiguratorERP
                        configTabs={(() => {
                            // const arr = [
                            //     'CRM',
                            //     'Contabilitate',
                            //     'Project Management',
                            //     'Antrepriza',
                            //     'Booking',
                            //     'Intermedieri imobiliare',
                            //     'Facility Management',
                            // ].filter((_, index) => moduleChoice[index]);

                            // arr.push('Configurator teme');
                            // return arr;

                            return ['Configurator teme'];
                        })()}
                        configActiveTab={configActiveTab}
                        setStep={setStep}
                        addedClient={addedClient}
                        setAddedClient={setAddedClient}
                        updateCompletionStatus={updateCompletionStatus}
                    />
                )}
                {/* {step === 2 && <ConfiguratorERP setStep={setStep} addedClient={addedClient} />} */}

                {/* Final Offer */}
                {step === 3 && addedClient && (
                    <OfertaFinala
                        finalOfferData={finalOfferData}
                        handleChangeFinalOfferData={handleChangeFinalOfferData}
                        subscriptionTypes={subscriptionTypes}
                        getPriceOptions={getPriceOptions}
                        submitFinalOffer={submitFinalOffer}
                        updatingStatus={updatingStatus}
                        updateCompletionStatus={updateCompletionStatus}
                    />
                )}
            </div>
        </div>
    );
};

const DetaliiClient = (props) => {
    const { setStep, addedClient, setAddedClient } = props;

    return (
        <div className="w-full">
            <ClientDetailsForm
                client={addedClient}
                submitButton={
                    <div className="mt-16">
                        <Button type="submit" color="primary" startIcon={<ArrowForwardIcon />}>
                            Pasul următor: 2. Module ERP
                        </Button>
                    </div>
                }
                onSuccess={(client) => {
                    setStep(1);
                    setAddedClient(client);
                }}
                canDisplayTitle={true}
            />
        </div>
    );
};
DetaliiClient.propTypes = {
    setStep: PropTypes.func,
    // eslint-disable-next-line
    addedClient: PropTypes.object,
    setAddedClient: PropTypes.func,
};
DetaliiClient.defaultProps = {
    setStep: () => null,
    addedClient: null,
    setAddedClient: () => null,
};

const ModuleERP = (props) => {
    const { setStep, submitModuleERP, moduleChoice, moduleNameMapper, handleModuleChoiceChange } = props;
    const features = useContext(FeatureContext);
    const listItemStyle = 'flex items-start';
    const ListLine = () => <span className="list-line" />;
    const ListCircle = () => <span className="list-circle" />;

    return (
        <div>
            <div className="mb-16 grid w-full grid-cols-7 gap-4">
                {features.map((feature) => (
                    <div key={feature.id}>
                        <ModuleWrapper
                            checked={moduleChoice[moduleNameMapper[feature.name]] || false}
                            setChecked={() =>
                                handleModuleChoiceChange(
                                    moduleNameMapper[feature.name],
                                    !moduleChoice[moduleNameMapper[feature.name]],
                                )
                            }
                            name={feature.name}
                            isSingle
                        >
                            <>
                                {/* MAIN FEATURE */}
                                {feature.features
                                    ?.filter((f) => f.name === feature.name)[0]
                                    ?.description?.fileds?.map((d) => (
                                        <>
                                            <p className={`${listItemStyle} my-4`}>
                                                <ListLine />
                                                {d.item}
                                            </p>

                                            {d.subItems.length ? (
                                                <>
                                                    {d.subItems.map((si, index) => (
                                                        <p key={index} className={`${listItemStyle} mb-2`}>
                                                            <ListCircle />
                                                            {si.item}
                                                        </p>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    ))}

                                {/* SUB */}
                                {feature.features
                                    .filter((f) => f.name !== feature.name)
                                    .map((f) => (
                                        <>
                                            <CheckItem
                                                name={f.name}
                                                key={f.id}
                                                small
                                                checked={moduleChoice[f.id]}
                                                setChecked={() =>
                                                    handleModuleChoiceChange(
                                                        moduleNameMapper[f.name],
                                                        !moduleChoice[moduleNameMapper[f.name]],
                                                    )
                                                }
                                            />

                                            {f.description?.fileds.map((d, index) => (
                                                <p key={index} className={`${listItemStyle} my-5`}>
                                                    <ListLine />
                                                    {d.item}
                                                </p>
                                            ))}

                                            <div className="h-2" />
                                        </>
                                    ))}
                            </>
                        </ModuleWrapper>
                    </div>
                ))}
            </div>

            <div className="flex items-center">
                <Button startIcon={<ArrowBackIcon />} onClick={() => setStep(0)}>
                    Pasul anterior: 1. Detalii Client
                </Button>

                <div className="w-4" />

                <Button color="primary" startIcon={<ArrowForwardIcon />} onClick={submitModuleERP}>
                    Pasul urmator: 3. Configurator ERP
                </Button>
            </div>
        </div>
    );
};
ModuleERP.propTypes = {
    setStep: PropTypes.func,
    submitModuleERP: PropTypes.func,
    // eslint-disable-next-line
    moduleChoice: PropTypes.object,
    // eslint-disable-next-line
    moduleNameMapper: PropTypes.object,
    handleModuleChoiceChange: PropTypes.func,
};
ModuleERP.defaultProps = {
    setStep: () => null,
    submitModuleERP: () => null,
    moduleChoice: [],
    handleModuleChoiceChange: () => null,
};

const ConfiguratorERP = (props) => {
    const {
        setStep,
        addedClient,
        setAddedClient,
        configTabs,
        configActiveTab,
        updatingStatus,
        updateCompletionStatus,
    } = props;

    return (
        <ClientConfigurator
            client={addedClient}
            configTabs={configTabs}
            configActiveTab={configActiveTab}
            onSuccess={(data) => {
                setAddedClient((prev) => ({ ...prev, ...data }));
                setStep(3);
            }}
            backButton={
                <Button
                    startIcon={updatingStatus ? <CircularProgress size={20} /> : <ArrowBackIcon />}
                    onClick={() => updateCompletionStatus('ERP_MODULES', 1)}
                    disabled={updatingStatus}
                >
                    Pasul anterior: 2. Module ERP
                </Button>
            }
            submitButton={
                <Button color="primary" startIcon={<ArrowForwardIcon />} disabled={updatingStatus}>
                    Pasul urmator: 4. Oferta finala
                </Button>
            }
        />
    );
};
ConfiguratorERP.propTypes = {
    setStep: PropTypes.func,
    addedClient: PropTypes.object,
    setAddedClient: PropTypes.func,
    configActiveTab: PropTypes.number,
    configTabs: PropTypes.array,
    updatingStatus: PropTypes.bool,
    updateCompletionStatus: PropTypes.func,
};
ConfiguratorERP.defaultProps = {
    setStep: () => {},
    addedClient: null,
    setAddedClient: () => {},
    configActiveTab: 0,
    configTabs: [],
    updatingStatus: false,
    updateCompletionStatus: () => {},
};

const OfertaFinala = (props) => {
    const {
        subscriptionTypes,
        finalOfferData,
        handleChangeFinalOfferData,
        getPriceOptions,
        submitFinalOffer,
        updatingStatus,
        updateCompletionStatus,
    } = props;

    return (
        <form className="flex w-full flex-col items-start justify-start" onSubmit={submitFinalOffer}>
            <h2 className="mb-8">Ofertă finală</h2>

            <div className="mb-16 w-96">
                <TextField
                    name="finalPrice"
                    label="Preț final (în funcție de tipul de abonament ales)"
                    type="number"
                    placeholder="ex: 110 EUR pe lună"
                    value={finalOfferData.finalPrice}
                    onChange={handleChangeFinalOfferData}
                />
            </div>

            <div className="mb-16 inline-flex w-96 flex-col">
                <CheckItem
                    checked={finalOfferData.hasDemo}
                    setChecked={(checked) => {
                        if (!checked)
                            handleChangeFinalOfferData({
                                target: {
                                    name: 'demoDays',
                                    value: '',
                                },
                            });

                        handleChangeFinalOfferData({
                            target: {
                                name: 'hasDemo',
                                value: checked,
                            },
                        });
                    }}
                    name="Oferă un demo"
                />

                <div className="h-5" />

                <TextField
                    name="demoDays"
                    label="Numărul de zile"
                    type="number"
                    placeholder="ex: 30 zile"
                    value={finalOfferData.demoDays}
                    disabled={!finalOfferData.hasDemo}
                    onChange={handleChangeFinalOfferData}
                />
            </div>

            <div className="flex items-center">
                <Button
                    startIcon={updatingStatus ? <CircularProgress size={20} /> : <ArrowBackIcon />}
                    onClick={() => updateCompletionStatus('ERP_CONFIGURATION', 2)}
                    disabled={updatingStatus}
                >
                    Pasul anterior: 3. Configurator ERP
                </Button>
                <div className="w-4" />
                <Button color="primary" startIcon={<ArrowForwardIcon />} type="submit" disabled={updatingStatus}>
                    ADAUGĂ CLIENT
                </Button>
            </div>
        </form>
    );
};

OfertaFinala.propTypes = {
    finalOfferData: PropTypes.object,
    handleChangeFinalOfferData: PropTypes.func,
    subscriptionTypes: PropTypes.array,
    getPriceOptions: PropTypes.func,
    submitFinalOffer: PropTypes.func,
    updatingStatus: PropTypes.bool,
    updateCompletionStatus: PropTypes.func,
};

OfertaFinala.defaultProps = {
    finalOfferData: {},
    handleChangeFinalOfferData: () => {},
    subscriptionTypes: [],
    getPriceOptions: () => {},
    submitFinalOffer: () => {},
    updatingStatus: false,
    updateCompletionStatus: () => {},
};

export default AddClientPage;
