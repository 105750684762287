import React, { useState } from 'react';
import { defaultTheme, hexToRgb, rgbToHex } from 'utils';
import PropTypes from 'prop-types';
import { ActionButton, LabelWrapper, Tabs, Toggle } from 'RaisisComponents';
import { Button, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import BasicTooltip from 'components/shared/BasicTooltip';

const translationKeys = {
    'TEXT COLORS': 'CULORI TEXT',
    'STATE COLORS': 'CULORI STATUS',
    'LAYOUT COLORS': 'CULORI LAYOUT',
    'UI COLORS': 'CULORI UI',
    'PDF COLORS': 'CULORI PDF',
    'MAIN TEXT': 'TEXT PRINCIPAL',
    'SECONDARY TEXT': 'TEXT SECUNDAR',
    'DARK TEXT': 'TEXT ÎNTUNECAT',
    'SIDEBAR TEXT': 'TEXT SIDEBAR',
    'BUTTONS TEXT': 'TEXT BUTOANE',
    'DARKER TEXT': 'TEXT MAI ÎNTUNECAT',
    ERROR: 'EROARE',
    SUCCESS: 'SUCCES',
    WARNING: 'AVERTISMENT',
    DISABLED: 'DEZACTIVAT',
    'MAIN LAYOUT': 'LAYOUT PRINCIPAL',
    'LIGHT LAYOUT': 'LAYOUT DESCHIS',
    'LIGHTER LAYOUT': 'LAYOUT MAI DESCHIS',
    'TRANSPARENT LAYOUT': 'LAYOUT TRANSPARENT',
    'MAIN PRIMARY': 'PRIMARĂ PRINCIPALĂ',
    'LIGHT PRIMARY': 'PRIMARĂ DESCHISĂ',
    'DARK PRIMARY': 'PRIMARĂ ÎNTUNECATĂ',
    'MAIN SECONDARY': 'SECUNDARĂ PRINCIPALĂ',
    'LIGHT SECONDARY': 'SECUNDARĂ DESCHISĂ',
    'LIGHTER SECONDARY': 'SECUNDARĂ MAI DESCHISĂ',
    'PDF TEXT': 'TEXT PDF',
    'PDF LINK TEXT': 'TEXT LINK PDF',
    'PDF LAYOUT TEXT': 'TEXT LAYOUT PDF',
    'PDF LAYOUT BACKGROUND': 'FUNDAL LAYOUT PDF',
};

const ThemeConfigurator = ({
    clientThemes,
    setClientThemes,
    canChangeTheme,
    setCanChangeTheme,
    activeThemeId,
    setActiveThemeId,
    editable,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const defaultNewThemeValue = { name: '', theme: JSON.parse(defaultTheme.theme) };
    const [editThemeId, setEditThemeId] = useState(null);
    const [newTheme, setNewTheme] = useState(defaultNewThemeValue);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = editThemeId ? ['Teme', 'Editează temă'] : ['Teme', 'Adaugă temă'];

    const handleChangeNewThemeName = (e) => {
        setNewTheme((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleChangeTheme = (section, e) =>
        setNewTheme((prev) => {
            const changedTheme = structuredClone(prev.theme);
            changedTheme[section][e.target.name]['color'] = hexToRgb(e.target.value);
            return { ...prev, theme: changedTheme };
        });

    const handleAddTheme = () => {
        if (newTheme.name.trim().length === 0) {
            enqueueSnackbar('Tema trebuie să aibă un nume!', { variant: 'error' });
            return;
        }

        setClientThemes((prev) => [
            ...prev,
            { ...newTheme, theme: JSON.stringify(newTheme.theme), id: crypto.randomUUID() },
        ]);
        setActiveTab(0);
        setNewTheme(defaultNewThemeValue);
        enqueueSnackbar('Tema a fost adăugată cu succses!', { variant: 'success' });
    };

    const handleMarkAsActiveTheme = (id) => setActiveThemeId(id);

    const handleDeleteTheme = (id) => setClientThemes((prev) => prev.filter((cT) => cT.id !== id));

    const handleInitiateEditTheme = (id) => {
        setEditThemeId(id);

        const selectedTheme = clientThemes.find((cT) => cT.id === id);

        setNewTheme({ ...selectedTheme, theme: JSON.parse(selectedTheme.theme) });
        setActiveTab(1);
    };

    const handleEditTheme = () => {
        if (newTheme.name.trim().length === 0) {
            enqueueSnackbar('Tema trebuie să aibă un nume!', { variant: 'error' });
            return;
        }

        setClientThemes((prev) =>
            prev.map((cT) => (cT.id === editThemeId ? { ...cT, name: newTheme.name, theme: newTheme.theme } : cT)),
        );
        setActiveTab(0);
        setNewTheme(defaultNewThemeValue);
        setEditThemeId(null);

        enqueueSnackbar('Tema a fost editată cu succses!', { variant: 'success' });
    };

    const handleCancel = () => {
        setActiveTab(0);
        setNewTheme(defaultNewThemeValue);
        setEditThemeId(null);
    };

    return (
        <div
            className={`flex max-w-max flex-col gap-8 rounded-md p-4 ${
                canChangeTheme ? 'bg-layout-background-dark ' : ''
            }`}
        >
            <div
                className={`flex max-w-max items-center gap-4 rounded-md bg-layout-background p-2 transition-all ${
                    canChangeTheme ? '' : '-translate-x-2 transform'
                }`}
            >
                <Toggle checked={canChangeTheme} setChecked={setCanChangeTheme} disabled={!editable} />
                <p>
                    {canChangeTheme
                        ? 'Dezactivează configuratorul de teme pentru aceasta companie'
                        : 'Activează configuratorul de teme pentru aceasta companie'}
                </p>
            </div>

            {canChangeTheme && (
                <div className="flex flex-col gap-8">
                    <div className="max-w-max">
                        <Tabs
                            tabs={tabs}
                            small
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            disabled={!editable || editThemeId}
                        />
                    </div>

                    <div className="flex flex-col gap-8">
                        {activeTab == 0 && (
                            <div className="flex flex-col gap-4">
                                {clientThemes.map((cT) => (
                                    <div
                                        key={cT.id}
                                        className="flex items-center justify-between gap-4 rounded-md bg-layout-background p-4"
                                    >
                                        <p>{cT.name}</p>
                                        <div className="flex items-center gap-4">
                                            <BasicTooltip tip="Marchează ca temă principală în ERP">
                                                <ActionButton
                                                    color="green"
                                                    icon={<CheckCircleIcon />}
                                                    onClick={() => handleMarkAsActiveTheme(cT.id)}
                                                    disabled={!editable || cT.id === activeThemeId}
                                                />
                                            </BasicTooltip>
                                            <BasicTooltip tip="Șterge">
                                                <ActionButton
                                                    disabled={
                                                        !editable ||
                                                        cT.id === clientThemes[0].id ||
                                                        cT.id === activeThemeId
                                                    }
                                                    color="red"
                                                    icon={<DeleteIcon />}
                                                    onClick={() => handleDeleteTheme(cT.id)}
                                                />
                                            </BasicTooltip>
                                            <BasicTooltip tip="Editează">
                                                <ActionButton
                                                    disabled={!editable || cT.id === clientThemes[0].id}
                                                    color="blue"
                                                    icon={<EditIcon />}
                                                    onClick={() => handleInitiateEditTheme(cT.id)}
                                                />
                                            </BasicTooltip>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {activeTab === 1 && (
                            <>
                                <div className="flex flex-col gap-4">
                                    <div className="max-w-md">
                                        <LabelWrapper
                                            label={editThemeId ? 'Editați numele temei' : 'Introduceți numele temei'}
                                        >
                                            <TextField
                                                placeholder="Nume temă"
                                                name="name"
                                                value={newTheme.name}
                                                onChange={handleChangeNewThemeName}
                                                disabled={!editable}
                                            />
                                        </LabelWrapper>
                                    </div>

                                    <LabelWrapper
                                        label={editThemeId ? 'Editați culorile temei' : 'Setați culorile temei'}
                                    >
                                        <div className="grid grid-cols-5 gap-x-3 gap-y-12 rounded-md bg-layout-background p-4 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
                                            {Object.entries(newTheme.theme).map(([section, sectionValues]) => (
                                                <div key={section} className="flex flex-col gap-6">
                                                    <h2 className="sm:text-1xl text-2xl">
                                                        {translationKeys[section.split('-').join(' ')]}
                                                    </h2>
                                                    {Object.entries(sectionValues).map(([colorKey, colorValues]) => {
                                                        const rgbValues = colorValues.color.split(' ');

                                                        return (
                                                            <div className="flex items-center gap-3" key={colorKey}>
                                                                <input
                                                                    className="h-10 w-16 rounded-md sm:h-14 sm:w-20"
                                                                    type="color"
                                                                    name={colorKey}
                                                                    value={rgbToHex(
                                                                        rgbValues.at(0),
                                                                        rgbValues.at(1),
                                                                        rgbValues.at(2),
                                                                    )}
                                                                    onChange={(e) => handleChangeTheme(section, e)}
                                                                    disabled={!editable}
                                                                />
                                                                <h3 className="text-xl sm:text-lg">
                                                                    {translationKeys[colorKey.split('-').join(' ')]}
                                                                </h3>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </LabelWrapper>
                                </div>
                                <div className="flex items-center gap-4">
                                    {editThemeId && (
                                        <Button startIcon={<CancelIcon />} onClick={handleCancel} disabled={!editable}>
                                            Anulează
                                        </Button>
                                    )}
                                    <Button
                                        color="secondary"
                                        startIcon={editThemeId ? <EditIcon /> : <SaveIcon />}
                                        onClick={editThemeId ? handleEditTheme : handleAddTheme}
                                        disabled={!editable}
                                    >
                                        {editThemeId ? 'Editează tema' : 'Adaugă tema'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

ThemeConfigurator.propTypes = {
    clientThemes: PropTypes.array,
    canChangeTheme: PropTypes.bool,
    setCanChangeTheme: PropTypes.func,
    setClientThemes: PropTypes.func,
    activeThemeId: PropTypes.string,
    setActiveThemeId: PropTypes.func,
    editable: PropTypes.bool,
};
ThemeConfigurator.defaultProps = {
    clientThemes: [],
    canChangeTheme: false,
    setCanChangeTheme: () => {},
    setClientThemes: () => {},
    activeThemeId: '',
    setActiveThemeId: () => {},
    editable: true,
};

export default ThemeConfigurator;
