import React, { useContext } from 'react';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Link } from '@material-ui/core';
import { TableSeparator } from 'RaisisComponents';
import PropTypes from 'prop-types';
import SubscriptionTypeContext from 'contexts/SubscriptionTypeContext';
import { formatDate } from 'utils';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import ClientTableFooter from './ClientTableFooter';

const ClientMembershipDetails = ({ client }) => {
    const subscriptionTypes = useContext(SubscriptionTypeContext);

    return client.subscriptionId ? (
        <>
            <TableContainer component={Box}>
                <Table>
                    <TableBody>
                        <TableSeparator />
                        <TableRow>
                            <TableCell>Dată semnare contract</TableCell>
                            <TableCell>{formatDate(client.subscription.startDate)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tip abonament</TableCell>
                            <TableCell>
                                {/* Abonament lunar, <Link href="/">Contract nr. 152</Link> */}
                                {(() => {
                                    const subscriptionName = subscriptionTypes.find(
                                        (sT) => sT.id === client.subscription.subscriptionTypeId,
                                    ).name;

                                    return subscriptionName.charAt(0).toUpperCase() + subscriptionName.slice(1);
                                })()}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sumă abonament</TableCell>
                            <TableCell>{client.subscription.subscriptionValue}</TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell>Număr utilizatori</TableCell>
                            <TableCell>2 Utilizatori</TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                            <TableCell>Estimare durată contract</TableCell>
                            <TableCell>Nespecificat</TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell>Status DEMO</TableCell>
                            <TableCell>
                                {client.subscription.hasDemo
                                    ? `Cu - ${client.subscription.numberOfDaysForDemo} zile`
                                    : 'Fară'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Domeniu activitate</TableCell>
                            <TableCell>{client.occupationDescription}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <ClientTableFooter /> */}
        </>
    ) : (
        <div className="flex items-center gap-2">
            <ErrorOutlineIcon fontSize="large" />
            <h2>Procesul de crearea a acestui client nu a fost finalizat.</h2>
        </div>
    );
};

export default ClientMembershipDetails;

ClientMembershipDetails.propTypes = {
    client: PropTypes.object,
};
ClientMembershipDetails.defaultProps = {
    client: {},
};
