import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Search } from '../Search/Search';

export const MultiDropdown = (props) => {
    const { options, selectedOptions, setSelectedOptions, placeholder, variant, icon } = props;
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className="relative inline-block w-full flex-shrink-0 cursor-pointer ">
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${variant} ${open ? 'clicked' : ''} ${
                        selectedOptions.length > 0 && 'hasValue'
                    } ${icon !== null && 'is-icon'}`}
                    onClick={() => setOpen(!open)}
                >
                    {icon !== null ? (
                        <>{icon}</>
                    ) : (
                        <>
                            <p className="pointer-events-none font-medium">
                                {selectedOptions.length > 0 ? `${selectedOptions.length} selectate` : placeholder}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform ${open ? 'rotate-180' : 'rotate-0'}`}
                                style={{ transition: 'transform .2s ease' }}
                            />
                        </>
                    )}
                </div>

                {/* Options */}
                {open && (
                    <div className="dropdown-options">
                        <div className="search-li sticky top-0 w-full bg-primary-light">
                            <Search light value={searchValue} setValue={setSearchValue} />
                        </div>
                        <ul className="z-50">
                            {options.map((option, index) => {
                                if (String(option).toLowerCase().search(searchValue.toLocaleLowerCase()) >= 0)
                                    return (
                                        // eslint-disable-next-line
                                        <li key={Math.random()} onClick={() => setSelectedOptions(index)}>
                                            {selectedOptions.indexOf(index) > -1 ? (
                                                <CheckBoxIcon style={{ fontSize: '1.20rem' }} />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon style={{ fontSize: '1.20rem' }} />
                                            )}
                                            <p className={`ml-2 text-sm font-medium`}>
                                                {Boolean(option) && option.length > 32
                                                    ? `${option.slice(0, 64).trim()}...`
                                                    : option}
                                            </p>
                                        </li>
                                    );
                                return null;
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

MultiDropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    selectedOptions: PropTypes.arrayOf(PropTypes.number),
    setSelectedOptions: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    variant: PropTypes.string,
};

MultiDropdown.defaultProps = {
    options: [],
    selectedOptions: [],
    setSelectedOptions: () => null,
    placeholder: 'Alege opțiunile',
    icon: null,
    variant: 'default',
};
